'use client';

import { incrementTelemetry, timerTelemetry } from 'core-events';

import { getSearchUrl, NavSearch, SearchFilterContentTypes, fetchTypeahead } from '@videoblocks/shared-components';

import { UserData } from '../../types';
import { imagesUrl } from '../lib/constants';

export default function NavSearchContainer(props: UserData) {
    const { auth } = props;

    const {
        hasAnySubscription,
        isEnterprise,
        permissions: { pproPluginEnabled },
    } = auth;

    const handleSubmit = (contentType, searchTerm, searchOrigin) => {
        const searchUrl = getSearchUrl({
            contentType: contentType,
            searchTerm: searchTerm,
            searchOrigin: searchOrigin,
        });
        window.location.href = searchUrl;
    };

    return (
        <NavSearch
            contentType={SearchFilterContentTypes.All_videos_content_type}
            onSubmit={handleSubmit}
            userHasAnySubscription={hasAnySubscription}
            isEnterpriseMember={isEnterprise}
            pproPluginEnabled={pproPluginEnabled}
            isSearchAppLoaded={() => false}
            incrementTelemetry={incrementTelemetry}
            imagesUrl={imagesUrl}
            fetchTypeahead={(
                contentType: SearchFilterContentTypes,
                query = '',
                typeaheadEnhancementExperiment = false,
            ) =>
                fetchTypeahead(contentType, query, typeaheadEnhancementExperiment, async () =>
                    timerTelemetry('search.frontEnd.typeAhead'),
                )
            }
        />
    );
}
